import { OutboundLink } from 'gatsby-plugin-google-analytics'
import React from 'react'
import Icon from './Icon'

interface Props {
    dark?: boolean
}

export const AppStoreButton = ({ dark }: Props) => {
    const className = dark ? "btn-app-store dark" : "btn-app-store"
    return (
        <OutboundLink className={className} href="https://apps.apple.com/us/app/muun-wallet/id1482037683">
            <Icon icon="app-store"/>&nbsp; App Store
        </OutboundLink>
    )
}
