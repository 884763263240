import React from 'react'
import "fontsource-rubik/400.css"
import "fontsource-rubik/500.css"
import "fontsource-rubik/700.css"
import "../scss/main.scss"

interface Props {
    className?: string
    children?: React.ReactNode
}

const Layout = ({ className, children }: Props) => {
    return (
        <div className={className}>
            {children}
        </div>
    )
}

export default Layout
