import React from 'react'
import { Helmet } from "react-helmet"

interface Props {
    title: string,
    description: string,
}

const Head = ({ title, description }: Props) => {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="description" content={description} />
        <meta property="og:url" content="https://muun.com"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Muun - Bitcoin Wallet"/>
        <meta property="og:description" content={description} />
        <meta property="og:image" content="https://muun.com/social-share.png" />
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="630"/>
        <meta name="twitter:title" content="Muun - Bitcoin Wallet"/>
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="https://muun.com/social-share.png" />
        <meta name="twitter:site" content="@muunwallet"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/site.webmanifest"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#3970db"/>
        <meta name="msapplication-TileColor" content="#3970db"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>
    )
}

export default Head
