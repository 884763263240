import React from 'react'

interface Props {
    icon: "twitter" | "github" | "app-store" | "play-store" | "read-more" | "menu" | "close"
}

const Icon = (props: Props) => {
    return <span className={"icon icon-" + props.icon}></span>
}

export default Icon
