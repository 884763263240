import React from 'react'
import { withPrefix } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { setLanguage } from "../language"

function replaceLanguage(path: string, language: string) {
    if (language == "es") {
        if (path.startsWith("/es/")) {
            return path
        }
        if (path.startsWith("/en/")) {
            return path.replace(/^\/en\//, "/es/")
        }
        return "/es" + path
    } else {
        if (path.startsWith("/en/")) {
            return path
        }
        if (path.startsWith("/es/")) {
            return path.replace(/^\/es\//, "/")
        }
        return path
    }
}

function pathWithLanguage(path: string, language: string): string {
    const prefix = withPrefix("/")
    const oldPathWithoutPrefix = path.substr(prefix.length - 1)
    const newPathWithoutPrefix = replaceLanguage(oldPathWithoutPrefix, language)
    return withPrefix(newPathWithoutPrefix)
}

export const LanguageSelect = () => {
    const intl = useIntl()
    const locale = intl.locale

    function onLanguageChange(e: React.SyntheticEvent<HTMLSelectElement>) {
        const language = e.currentTarget.value

        setLanguage(language)

        const path = window.location.pathname
        const newPath = pathWithLanguage(path, language)

        if (path !== newPath) {
            window.location.pathname = newPath
        }
    }

    return (
        <select className="language-select ml-3" name="lang" onChange={onLanguageChange} value={locale}>
            <option value="en">English</option>
            <option value="es">Español</option>
        </select>
    )
}


