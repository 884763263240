import { OutboundLink } from 'gatsby-plugin-google-analytics'
import React from 'react'
import Icon from './Icon'

interface Props {
    dark?: boolean
}

export const PlayStoreButton = ({ dark }: Props) => {
    const className = dark ? "btn-play-store dark" : "btn-play-store"
    return (
        <OutboundLink className={className} href="https://play.google.com/store/apps/details?id=io.muun.apollo">
            <Icon icon="play-store"/>&nbsp; Google Play
        </OutboundLink>
    )
}
