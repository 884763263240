import React from 'react'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'
import Icon from './Icon'
import { LanguageSelect } from './LanguageSelect'

const Footer = () => {
    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <p className="d-inline">
                            © 2024 Muun Wallet Inc.
                            &nbsp;&nbsp;|&nbsp; <Link to="/privacy_policy.html">Privacy policy</Link>
                        </p>
                        <LanguageSelect />
                    </div>
                    <div className="col-auto d-none d-lg-block">
                        <nav className="pt-md-2">
                            <ul>
                                <li>
                                    <Link to="/contact" activeClassName="active">
                                        <FormattedMessage id="contact" defaultMessage="Contact" />
                                    </Link>
                                </li>
                                <li>
                                    <a href="https://blog.muun.com/">
                                        <FormattedMessage id="blog" defaultMessage="Blog" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/muunwallet" target="_blank" rel="noopener">
                                        <FormattedMessage id="follow_us" defaultMessage="Follow us" />&nbsp; <Icon icon="twitter" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://github.com/muun" target="_blank" rel="noopener">
                                        <FormattedMessage id="audit_us" defaultMessage="Audit us" />&nbsp; <Icon icon="github" />
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
