import cookies from "js-cookie"

const COOKIE_NAME = "lang"

function isValidLanguage(lang: string) {
  return ["en", "es"].includes(lang)
}

export function getLanguage() {
  let lang = cookies.get(COOKIE_NAME)

  try {
    if (!isValidLanguage(lang)) {
      const browserLang = navigator.language || navigator.userLanguage
      lang = browserLang.split("-")[0]
    }
  } catch (e) {
    return "en"
  }

  return isValidLanguage(lang) ? lang : "en"
}

export function setLanguage(language: string) {
    cookies.set(COOKIE_NAME, language)
}
