import React from 'react'
import classNames from 'classnames'
import Icon from './Icon'
import { AppStoreButton } from './AppStoreButton'
import { PlayStoreButton } from './PlayStoreButton'
import muunLogo from "../assets/muun-logo.svg"
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl'

interface Props {
    className?: string
}

const Header = ({ className }: Props) => {
    const intl = useIntl()
    const [open, setOpen] = React.useState(false)

    function onNavbarTogglerClick() {
        setOpen(!open)
    }

    if (typeof document !== "undefined") {
        document.body.classList.toggle("noscroll", open)
    }

    return (
        <header className={classNames("site-header", {"fixed": open}, className)}>
            <div className="container">
                <Link to="/"><img className="brand" src={muunLogo} alt="Muun"/></Link>
                <nav className="site-nav">
                    <button className="navbar-toggler" onClick={onNavbarTogglerClick} type="button" aria-expanded="false" aria-label={intl.formatMessage({ id: "toggle_navigation", defaultMessage: "Toggle navigation" })}>
                        <span className="icon icon-menu"></span>
                    </button>
                    <div className={"navbar-collapse container " + (open ? "open" : "")}>
                        <div className="d-md-none">
                            <Link to="/"><img className="brand" src={muunLogo} alt="Muun"/></Link>
                            <button className="navbar-toggler" style={{float: 'right', marginTop: 4}} onClick={onNavbarTogglerClick} type="button" aria-expanded="false" aria-label={intl.formatMessage({ id: "toggle_navigation", defaultMessage: "Toggle navigation" })}>
                                <span className="icon icon-close"></span>
                            </button>
                        </div>
                        <ul>
                            <li>
                                <Link to="/contact" activeClassName="active">
                                    <FormattedMessage id="contact" defaultMessage="Contact" />
                                </Link>
                            </li>
                            <li>
                                <a href="https://blog.muun.com/">
                                    <FormattedMessage id="blog" defaultMessage="Blog" />
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/muunwallet" target="_blank" rel="noopener">
                                    <span className="d-md-none"><FormattedMessage id="follow_us" defaultMessage="Follow us" />&nbsp;</span> <Icon icon="twitter" />
                                </a>
                            </li>
                            <li>
                                <a href="https://github.com/muun" target="_blank" rel="noopener">
                                    <span className="d-md-none"><FormattedMessage id="audit_us" defaultMessage="Audit us" />&nbsp;</span> <Icon icon="github" />
                                </a>
                            </li>
                        </ul>
                        <div className="store-links d-md-none">
                            <div className="col-mobile">
                                <AppStoreButton />
                            </div>
                            <div className="col-mobile">
                                <PlayStoreButton />
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header
